<template>
  <hr 
    :width="width" 
    class="mt-3"
    :color="color"
    :background-color="color"
  >  
</template>

<script>
  export default {
    name: "BaseTitleDivider",
    props: {
      width: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        default: "sky-blue"
      }
    }
  }
</script>

<style lang="scss" scoped>
hr {
  border: none;
  height: 4px;
}
</style>