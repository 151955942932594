<template>
  <v-alert 
    dismissible
    :type="typeAlert" 
    :value="value" 
    class="alert mt-10"
    width="500"
    border="left"
    prominent
    transition="scale-transition"
  >
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: "BaseAlert",
  props: {
    typeAlert: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: undefined,
    },
    value: {
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.alert{
  position: fixed;
  right:0;
  z-index: 100;
}
</style>