<template>
  <v-card
    class="base-card-style"
    flat
    v-bind="$attrs"
  >
    <v-card-title>
      <span class="text-uppercase base-card-style__title mb-1">
        {{ title }}
      </span>
    </v-card-title>
    <v-card-subtitle v-if="subtitle">
      <span class="base-card-style__subtitle sky-blue--text mb-8">
        {{ subtitle }}
      </span>
    </v-card-subtitle>
    <v-card-text>
      <p class="sky-grey--text text--lighten-2 text-justify base-card-style__content">
        {{ text }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "BaseCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
.base-card-style {
  &__title {
    font-family: 'fjalla one',sans-serif;
    font-size: 26px;
  }
  &__subtitle {
    font-family: 'fjalla one',sans-serif;
    font-size: 22px;
  }
  &__content {
    font-size: 16px;
  }
}
</style>