<template>
  <v-card 
    :color="color"
    height="auto"
    :width="width"
    class="pa-8"
    elevation="4"
    v-bind="$attrs"
  >
    <v-card-title class="white--text text-uppercase card-title my-8">
      <span class="mx-auto">{{ title }}</span>
    </v-card-title>
    <v-card-text 
      class="white--text text-justify"
      v-for="(paragraph,i) in paragraphList" 
      :key="i"
    >
      <p class="card-text">
        {{ paragraph }}
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "BasePresentationCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    paragraphList: { 
      type: Array, 
      required: true
    },
    color: {
      type: String,
      default: "sky-blue"
    },
    width: {
      type: String,
      default: "313"
    }
  }
}
</script>

<style lang="scss" scoped>
.card-title {
  font-family: 'fjalla one',sans-serif;
  font-size: 26px;
  word-break: normal;
}
.card-text {
  font-size: 18px;
}
</style>