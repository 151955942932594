<template>
  <v-sheet class="fill-height" color="sky-grey" width="100vw">
    <base-alert
      :type-alert="alertType"
      :value="alertValue"
      :message="alertMessage"
    />
    <v-row>
      <!-- Section Accueil -->
      <span id="accueil" class="anchor" />
      <v-col class="pa-0">
        <div class="container-accueil pa-0 ma-0">
          <video
            autoplay
            muted
            loop
            class="home-video"
            poster="/assets/photos/poster_header.jpg"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <source
              src="/assets/videos/intro_l2sky_1080p_5mbps.mp4"
              type="video/mp4"
            />
          </video>
          <v-img
            v-else
            class="home-image"
            src="/assets/photos/poster_header.jpg"
            lazy-src="/assets/photos/poster_header.jpg"
            alt="photo poster d'un vol en drone"
          />
          <base-home-title
            class="container-accueil-title"
            title="Donnez de la hauteur à vos projets"
          />
        </div>
      </v-col>
    </v-row>
    <!-- Section Services proposés -->
    <span id="services" class="anchor" />
    <v-row class="mb-16">
      <v-col class="pa-0">
        <div class="d-flex justify-center">
          <h2
            class="text-uppercase sky-grey--text text--darken-1 my-16"
            :class="$vuetify.breakpoint.smAndUp ? 'text-h2' : 'text-h5'"
          >
            Nos Services
            <base-title-divider width="50%" />
          </h2>
        </div>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col
            v-for="(service, i) in servicesList"
            :key="i"
            cols="4"
            class="d-flex justify-center height-col"
            :class="{ 'mt-12': service.shift }"
          >
            <base-service-card
              :src="service.image"
              :title="service.name"
              :item-list="service.children"
              :alt="service.alt"
            />
          </v-col>
        </v-row>
        <v-row v-else v-for="(service, i) in servicesList" :key="i">
          <v-col>
            <base-service-card
              :src="service.image"
              :title="service.name"
              :item-list="service.children"
              :alt="service.alt"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Section Pourquoi nous choisir -->
    <span id="pourquoi-nous" class="anchor" />
    <v-row
      class="mt-16"
      style="height: 472px"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-col cols="5" class="pa-0 d-flex align-center">
        <v-img
          height="472"
          width="700"
          src="/assets/photos/photo-paysage.jpg"
          lazy-src="/assets/photos/photo-paysage.jpg"
          alt="image de paysage"
        />
      </v-col>
      <v-col cols="7" class="pa-0">
        <base-quality-card
          title="Toutes les raisons de travailler avec nous"
          :items-list="qualityList"
          class="pa-4"
        />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <base-quality-card
          title="Toutes les raisons de travailler avec nous"
          :items-list="qualityList"
          class="pa-4"
        />
      </v-col>
    </v-row>
    <!-- Section Présentation de l'équipe -->
    <span id="equipe" class="anchor" />
    <v-row>
      <v-col class="pb-0">
        <div class="d-flex justify-center">
          <h2
            class="text-uppercase sky-grey--text text--darken-1"
            :class="[
              $vuetify.breakpoint.smAndUp ? 'text-h2' : 'text-h5',
              $vuetify.breakpoint.mdAndUp ? 'container-equipe-title' : 'my-16',
            ]"
          >
            Notre équipe
            <base-title-divider width="50%" />
          </h2>
        </div>
        <v-row class="mb-16" v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="d-flex justify-end">
            <base-presentation-card
              title="Qui sommes-nous ?"
              :paragraph-list="presentation"
            />
          </v-col>
          <v-col class="d-flex flex-column">
            <div class="mb-4 mx-auto">
              <v-img
                height="320"
                width="270"
                src="/assets/photos/equipe-pilote.jpg"
                lazy-src="/assets/photos/equipe-pilote.jpg"
                alt="photo du pilote"
                class="container-equipe-image"
              />
            </div>
            <base-card
              height="320"
              width="270"
              :title="drone.name"
              :subtitle="drone.subtitle"
              :text="drone.description"
              class="mx-auto"
              color="transparent"
            />
          </v-col>
          <v-col class="d-flex flex-column pl-0">
            <base-card
              height="320"
              width="270"
              :title="pilote.name"
              :subtitle="pilote.subtitle"
              :text="pilote.description"
              class="mb-3"
              color="transparent"
            />
            <div>
              <v-img
                height="320"
                width="270"
                src="/assets/photos/equipe-drone.jpg"
                lazy-src="/assets/photos/equipe-drone.jpg"
                alt="photo du drone"
                class="container-equipe-image"
              />
            </div>
          </v-col>
        </v-row>
        <v-row v-else class="mb-8">
          <v-col>
            <base-presentation-card
              title="Qui sommes-nous ?"
              :paragraph-list="presentation"
              width="85%"
              class="mx-auto text-wrap"
              shaped
            />
            <v-row class="pa-4 mt-6">
              <v-col>
                <base-card
                  height="320"
                  width="auto"
                  :title="pilote.name"
                  :subtitle="pilote.subtitle"
                  :text="pilote.description"
                  class="ml-3"
                  color="transparent"
                />
              </v-col>
              <v-col>
                <v-img
                  height="320"
                  width="270"
                  src="/assets/photos/equipe-pilote.jpg"
                  lazy-src="/assets/photos/equipe-pilote.jpg"
                  alt="photo du pilote"
                  class="container-equipe-image mx-auto"
                />
              </v-col>
            </v-row>
            <v-row class="pa-4">
              <v-col>
                <v-img
                  height="320"
                  width="270"
                  src="/assets/photos/equipe-drone.jpg"
                  lazy-src="/assets/photos/equipe-drone.jpg"
                  alt="photo du drone"
                  class="container-equipe-image mx-auto"
                />
              </v-col>
              <v-col>
                <base-card
                  height="320"
                  width="auto"
                  :title="drone.name"
                  :subtitle="drone.subtitle"
                  :text="drone.description"
                  class="mx-auto"
                  color="transparent"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Section Footer avec formulaire de contact -->
    <span id="contact" class="anchor" />
    <v-row class="section-contact-form pb-16">
      <v-col>
        <div class="d-flex justify-center">
          <h2
            class="white--text my-16 px-8"
            :class="
              $vuetify.breakpoint.smAndDown
                ? 'base-footer-title__mobile'
                : 'base-footer-title'
            "
          >
            Avec L2Sky vos projets prennent de la hauteur...
            <base-title-divider width="35%" color="#00e0e0" />
          </h2>
        </div>
        <v-row v-if="$vuetify.breakpoint.mdAndUp">
          <v-col class="d-flex flex-column ma-8 base-border-right">
            <h2 class="text-uppercase text-h3 text-center white--text mb-6">
              Nous contacter
            </h2>
            <base-contact-form class="mx-auto" @click="validateAndSubmit" />
          </v-col>
          <v-col class="ma-8">
            <the-footer
              color="sky-grey darken-1"
              class="d-flex align-center justify-center mt-14 text-center"
            />
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <h2 class="text-uppercase text-h3 text-center white--text mb-6">
              Nous contacter
            </h2>
            <base-contact-form class="mx-auto" @click="validateAndSubmit" />
            <the-footer
              color="sky-grey darken-1"
              class="mt-14 text-center mx-auto"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import BaseHomeTitle from "@/components/BaseHomeTitle.vue";
import BaseServiceCard from "@/components/BaseServiceCard.vue";
import BaseQualityCard from "@/components/BaseQualityCard.vue";
import BaseCard from "@/components/BaseCard.vue";
import BaseContactForm from "@/components/BaseContactForm.vue";
import API from "@/api.js";
import BaseAlert from "@/components/BaseAlert.vue";
import TheFooter from "@/components/TheFooter.vue";
import BaseTitleDivider from "@/components/BaseTitleDivider.vue";
import BasePresentationCard from "@/components/BasePresentationCard.vue";

export default {
  name: "Home",
  components: {
    BaseHomeTitle,
    BaseServiceCard,
    BaseQualityCard,
    BaseCard,
    BaseContactForm,
    BaseAlert,
    TheFooter,
    BaseTitleDivider,
    BasePresentationCard,
  },
  data() {
    return {
      servicesList: [
        {
          name: "Photo & Vidéo",
          image: "/assets/photos/service_photovideo.jpg",
          alt: "image d'illustration pour le service photographie",
          children: [
            "Mariages & Fêtes",
            "Évènements professionnels",
            "Prises de vue immobilière",
            "Clips vidéo",
            "Publicité",
            "Multimédia (sites, marketing)",
          ],
        },
        {
          name: "Inspection",
          image: "/assets/photos/service_inspection.jpg",
          alt: "image d'illustration pour le service inspection",
          shift: true,
          children: [
            "Surveillance de site",
            "Analyses structurelles",
            "Inspection de bâtiments",
            "Architecture",
            "Chantier",
          ],
        },
        {
          name: "Formation",
          image: "/assets/photos/service_formation.jpg",
          alt: "image d'illustration pour le service formation",
          children: ["A venir courant 2022 !"],
        },
      ],
      qualityList: [
        {
          name: "Qualité",
          icon: "mdi-file-certificate-outline",
          content:
            "Notre équipe est à votre écoute pour évaluer vos demandes et répondre à vos besoins les plus fous!",
        },
        {
          name: "Réactivité",
          icon: "mdi-drone",
          content:
            "Une idée ? Un projet ? Envoyez-nous un message, et nous reviendrons très vite vers vous pour en faire une réalité!",
        },
        {
          name: "Efficacité",
          icon: "mdi-diamond-stone",
          content:
            " Nous ne travaillons qu'avec des pilotes formés et du matériel certifié pour les particularités de chaque mission.",
        },
      ],
      presentation: [
        "Des passionnés, formés par des professionnels, amoureux du détail et du travail bien fait. Rien que ça.",
        "Et si vous voulez plus de détails, voici les membres de l'équipe au complet",
      ],
      pilote: {
        name: "Nassim Lakebir",
        subtitle: "Le pilote",
        description:
          "Développeur passionné, grand fan de nouvelles technologies, j'ai décidé de suivre une formation complète pour céder au plaisir du drone, découvrir les paysages de vacances sous de nouveaux angles, et partager ce plaisir avec le plus grand nombre de personnes.",
      },
      drone: {
        name: "UAS252665",
        subtitle: "Le drone",
        description:
          "Derrière ce nom barbare se cache le membre le plus important de l'équipe 😎. Le premier venu de notre flotte est un DJI Air 2S, mêlant à la fois qualité, maniabilité et performances, pour transformer vos rêves de projets en visuels aux millions de pixels !",
      },
      alertType: "",
      alertValue: false,
      alertMessage: "",
    };
  },
  methods: {
    validateAndSubmit(payload) {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("contactUs").then((token) => {
          const contactData = {
            name: payload.name,
            email: payload.email,
            message: payload.message,
            recaptcha: token,
          };
          console.log(contactData);
          API.post(process.env.VUE_APP_API_URL, contactData)
            .then((response) => {
              if (response.status === 200) {
                this.alertType = "success";
                this.alertValue = true;
                this.alertMessage = "Votre message a bien été envoyé !";
                setTimeout(
                  () => this.$router.go(this.$router.currentRoute),
                  3000
                );
              }
            })
            .catch((error) => {
              console.log(error);
              this.alertType = "error";
              this.alertValue = true;
              this.alertMessage =
                "Nous sommes désolés, une erreur s'est produite lors de l'envoi de votre message";
            });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.anchor {
  display: block;
  height: 100px;
  margin-top: -90px;
  visibility: hidden;
}
.home-video,
.home-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.container-accueil {
  position: relative;
  height: 95vh;

  &-title {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.height-col {
  height: 605px;
}
.container-equipe {
  &-title {
    margin-top: 200px;
    margin-bottom: 80px;
  }
  &-image {
    border-radius: 4px;
  }
  &-card-title {
    font-family: "fjalla one", sans-serif;
    font-size: 26px;
  }
  &-card-text {
    font-size: 18px;
  }
}
.section-contact-form {
  background-color: map-deep-get($colors, "sky-grey", "darken-1");
}
.base-footer-title {
  font-family: "fjalla one", sans-serif;
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 1px;

  &__mobile {
    font-family: "fjalla one", sans-serif;
    font-weight: 400;
    font-size: 2rem;
    letter-spacing: 1px;
  }
}
.base-border-right {
  border-right: 1px solid grey;
}
</style>
