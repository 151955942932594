<template>
  <div 
    v-if="$vuetify.breakpoint.mdAndUp"
    class="d-flex flex-column"
  >
    <v-img
      height="370"
      max-height="370"
      width="250"
      :src="src"
      :lazy-src="src"
      :alt="alt"
      class="image-effect"
    />
    <h3 class="text-h3 text-uppercase mt-8 ml-2">
      {{ title }}
    </h3>
    <ul class="mt-4 ml-2">
      <li 
        v-for="(item,i) in itemList" 
        :key="i"
        class="sky-grey--text text--darken-1 item-list mb-1"
      >
        {{ item }}
      </li>
    </ul> 
  </div>
  <div v-else>
    <h3 class="text-h3 text-uppercase my-8 text-center">
      {{ title }}
    </h3>
    <div class="d-flex justify-center pa-8">
      <v-img
        :height="$vuetify.breakpoint.xs ? '200' : '300'"
        :max-height="$vuetify.breakpoint.xs ? '200' : '300'"
        :width="$vuetify.breakpoint.xs ? '130' : '200'"
        :max-width="$vuetify.breakpoint.xs ? '130' : '200'"
        :src="src"
        :lazy-src="src"
        :alt="alt"
        class="mobile-style-image"
      />
      <ul class="mt-4 ml-5 mr-3">
        <li 
          v-for="(item,i) in itemList" 
          :key="i"
          class="sky-grey--text text--darken-1 mb-3 text-wrap"
          :class="$vuetify.breakpoint.xs ? 'item-list__mobile' : 'item-list'"
        >
          {{ item }}
        </li>
      </ul> 
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseServiceCard",
  props: {
    src: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    itemList: {
      type: Array,
      default: () => []
    },
    alt: {
      type: String,
      default: 'image d\'illustration'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables';
li::marker  {
  color: map-deep-get($colors, 'sky-blue', 'base');
}
.item-list {
  font-family:'fjalla one',sans-serif;
  font-size: 18px;

  &__mobile{
    font-family:'fjalla one',sans-serif;
    font-size: 15px;
  }
}
.image-effect{
  transition: filter 0.6s ease-out, border 500ms ease-out;
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  border : 8px solid transparent;    
  -webkit-transition : filter 0.6s ease-out, border 500ms ease-out; 
  -moz-transition : filter 0.6s ease-out, border 500ms ease-out;
  -o-transition : filter 0.6s ease-out, border 500ms ease-out;
}
.image-effect:hover {
  filter: none;
  -webkit-filter: grayscale(0);
  border : 8px solid map-deep-get($colors, 'sky-blue', 'darken-1'); 
}
.mobile-style-image{
  border : 3px solid map-deep-get($colors, 'sky-grey', 'darken-1'); 
}
</style>