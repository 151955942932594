<template>
  <v-card flat :color="color" width="80%" height="601">
    <v-card-text class="white--text d-flex flex-column footer-content-style">
      <span class="mb-3">
        <span class="bold">L2Sky</span> © 2021. All rights reserved.
      </span>
      <span class="mb-3">
        Exploitant drone déclaré auprès de la DGAC -
        <span class="bold">N°ED15033</span>
      </span>
      <span class="mb-3">
        Site internet développé par
        <a
          class="white--text"
          href="https://www.linkedin.com/in/anne-laure-sievering/"
          target="_blank"
          >Anne-Laure Sievering</a
        >
      </span>
      <span>
        <a class="white--text" href="#" target="_blank">Mentions Légales</a>
      </span>
      <v-img
        height="400"
        width="500"
        src="/assets/photos/service_formation.jpg"
        lazy-src="/assets/photos/service_formation.jpg"
        alt="photo du footer"
        class="mt-10 mx-auto"
        style="filter: sepia(40%)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "TheFooter",
  props: {
    color: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 600;
}
.footer-content-style {
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 16px;
}
</style>