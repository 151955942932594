<template>
  <div>
    <h1 
      class="white--text text-center home-title" 
      :class="$vuetify.breakpoint.smAndUp ? 'text-h1' : 'text-h4'"
    >
      {{ title }}
    </h1>
  </div>
</template>

<script>
export default {
  name: "BaseHomeTitle",
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.home-title {
  text-shadow: black 0.1em 0.1em 0.2em
}
</style>
