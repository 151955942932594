<template>
  <v-card class="pa-8" width="80%" shaped>
    <v-form ref="form" v-model="isFormValid">
      <v-row>
        <v-col>
          <v-text-field
            v-model="name"
            :rules="nameRules"
            label="Nom *"
            color="sky-blue darken-1"
            outlined
            required
          />
          <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail *"
            color="sky-blue darken-1"
            type="email"
            outlined
            required
          />
          <v-textarea
            v-model="message"
            :rules="messageRules"
            outlined
            label="Votre message *"
            value="Vous pouvez écrire ici votre message"
            color="sky-blue darken-1"
            required
            auto-grow
            filled
          />
          <p class="recaptcha-text text-justify grey--text">
            Ce site est protégé par reCaptcha. En remplissant ce formulaire,
            vous confirmez approuver la
            <a href="https://policies.google.com/privacy" target="_blank">
              Politique de Confidentialité
            </a>
            et les
            <a href="https://policies.google.com/terms" target="_blank">
              Conditions d'Utilisation
            </a>
            de Google reCaptcha.
          </p>
          <v-checkbox
            v-model="checkboxValue"
            :rules="agreeRules"
            label="J'accepte que mes données soient conservées, dans le seul et unique but d'être recontacté par L2Sky"
            color="sky-blue darken-1"
            required
            class="my-4"
          />
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions class="mt-4 px-0">
      <v-btn text @click="$refs.form.reset()"> Effacer </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        color="sky-blue darken-1"
        class="white--text"
        :disabled="!isFormValid || isSending"
        :class="{ disabled: !isFormValid }"
        @click="emitClick()"
      >
        {{ isSending ? "Envoi en cours..." : "Envoyer" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "BaseContactForm",
  data() {
    return {
      emailRules: [
        (v) => !!v || "Veuiller renseigner votre email.",
        (v) => /.+@.+\..+/.test(v) || "Veuiller indiquer un email valide.",
      ],
      nameRules: [(v) => !!v || "Veuiller renseigner votre nom"],
      messageRules: [
        (v) =>
          (!!v && v.length >= 10) ||
          "Le message doit comporter au moins 10 caractères",
      ],
      agreeRules: [(v) => !!v || "Vous devez accepter pour continuer."],
      name: "",
      message: "",
      email: "",
      checkboxValue: false,
      isFormValid: false,
      isSending: false,
    };
  },
  methods: {
    emitClick() {
      this.$refs.form.validate();
      this.isSending = true;
      this.$emit("click", {
        message: this.message,
        name: this.name,
        email: this.email,
        isAgree: this.checkboxValue,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";
.disabled.v-btn.v-btn--disabled.v-btn--has-bg {
  opacity: 0.5;
  background-color: map-deep-get($colors, "sky-blue", "darken-1") !important;
  color: white !important;
}
.recaptcha-text {
  font-size: 12px;
}
</style>