<template>
  <v-card 
    color="sky-blue"
    height="100%"
  >
    <v-card-title class="mb-6">
      <h2 
        class="text-uppercase white--text py-6"
        :class="$vuetify.breakpoint.mdAndUp ? 'title-card' : 'title-card__mobile'"
      >
        {{ title }}
      </h2>
      <base-title-divider 
        width="30%" 
        color="white" 
      />
    </v-card-title>
    <v-row>
      <v-col v-for="(item,i) in itemsList" :key="i">
        <div class="d-flex flex-column">
          <div class="d-flex">
            <v-icon
              color="white"
              size="29"
            >
              {{ item.icon }}
            </v-icon>
            <h3 class="text-h3 text-uppercase ml-2 white--text">
              {{ item.name}}
            </h3>
          </div>
          <v-card-text class="pl-1">
            <p class="white--text content-custom">
              {{ item.content }}
            </p>
          </v-card-text>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import BaseTitleDivider from '@/components/BaseTitleDivider.vue'
export default {
  name: "BaseQualityCard",
  components: {
    BaseTitleDivider
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    itemsList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.content-custom{
  font-size: 16px;
  margin-right: 3em;
  font-weight: none;
  line-height: 2rem;
}
.title-card{
  font-size: 3em;
  line-height: 1.23em;
  text-align: left;
  font-family: 'fjalla one',sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  word-break: break-word;

  &__mobile {
    font-size: 2em;
    line-height: 1.23em;
    text-align: left;
    font-family: 'fjalla one',sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    word-break: break-word;
  }
}
</style>